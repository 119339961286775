import store from '@/store/index'
import BreadcrumbsManager from '@/mixins/BreadcrumbManager.js'

export const paginationMixin = {
  mixins: [BreadcrumbsManager],
  watch: {
    options: {
      handler() {
        this.getDataFromAPI(this.itemType).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      }
    },
    search: {
      handler() {
        if (this.search.length >= 4) {
          this.getDataFromAPI().then(data => {
            // console.log(data.items)
            this.items = data.items
            this.total = data.total
          })
        }
      }
    },
    itemType: {
      handler() {
        this.getDataFromAPI(this.itemType, 0).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      }
    },
    deleteItem: {
      handler() {
        this.getDataFromAPI(this.itemType).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.totalEvents = data.total
        })
      }
    },
    deep: true
  },
  data() {
    return {
      search: '',
      dialogDelete: false,
      DeleteItem: {},
      deletedItemName: '',
      items: [],
      total: 0,
      defaultLimit: 10,
      itemsPerPage: [5, 10, 50, 100],
      options: {}
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    routeTo.params.type = routeFrom.params.type
    next()
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    routeTo.params.type = routeFrom.params.type
    next()
  },
  methods: {
    deleteItem(item) {
      this.dialogDelete = true
      this.deletedItemName = item.name || item.title || item.login || item.platform_id
      this.DeleteItem = item
    },
    closeDelete() {
      this.dialogDelete = false
    }
  },
  computed: {
    systemId() {
      return this.$route.params.system_id
    },
    system() {
      return this.$store.state['systems']['system']
    },
    itemType() {
      return this.$route.query.type || 'all'
    },
    breadcrumbs() {
      return this.$store.state.breadcrumbs.breadcrumbs
    }
  }
}
